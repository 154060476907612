import { createRouter, createWebHistory } from 'vue-router'
import { useSectionsStore } from '@/stores/section'
import { storeToRefs } from 'pinia'

const GeneratePage = () => import('@/views/GeneratePage.vue')
const DynamicPage = () => import('@/views/DynamicPage.vue')
const SearchPage = () => import('@/views/SearchPage.vue')
const ErrorPage = () => import('@/views/404.vue')

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/components/layout/DefaultLayout.vue'),
      children: [
        { path: '/', component: GeneratePage },
        {
          path: '/:id/:slug',
          component: DynamicPage
        },
        {
          path: '/:slug',
          component: DynamicPage
        },
        { path: '/program-tv', component: () => import('@/views/ProgramTvPage.vue') },
        {
          path: '/program-tv/:programName/:id',
          component: () => import('@/views/ProgramTvDetailPage.vue')
        },
        { path: '/program-tv', component: () => import('./views/ProgramTvPage.vue') },
        { path: '/programy-tv-detal', component: () => import('./views/ProgramTvDetailPage.vue') },
        { path: '/pogoda', component: () => import('./views/WeatherPage.vue') },
        { path: '/aplikacja', component: () => import('./views/AppLp.vue') },
        // { path: '/wiadomosci', component: () => import('./views/programs/ProgramHomePage.vue') },
        {
          path: '/szukaj',
          component: SearchPage
        },
        {
          path: '/404',
          component: ErrorPage
        }
      ]
    },
    {
      path: '/:id/:slug/webview',
      component: () => import('@/views/ArticleWebviewPage.vue')
    }
    // {
    //   path: '/:id/iframe',
    //   component: () => import('@/views/elections/ElectionsIframePage.vue')
    // }
  ],
  scrollBehavior(to) {
    if (!to.hash) {
      return { top: 0 }
    }
  }
})

// router.beforeResolve((to, from, next) => {
//   console.log('beforeResolve')
// });

router.beforeEach((to, from, next) => {
  const interstitialWrapper = document.querySelector('.interstitial__wrapper')

  if (interstitialWrapper && interstitialWrapper.classList.contains('loaded') && !getCookie("interstitial")) {
    interstitialWrapper.classList.add('show')
    if (navigator.cookieEnabled) {
        document.cookie = 'interstitial=' + to.fullPath + '; max-age=3600';
    }

    const interstitialBtn = document.querySelector('.interstitial__btn');
    const counterWrapper = document.querySelector('.interstitial__counter');
    const counterWrapperText = document.querySelector('.interstitial__counter-number');
    const progressbar = document.querySelector('.interstitial__progressbar');

    if (counterWrapper) {
      let counter = 8;
      const counterInterval = setInterval(() => {
          counterWrapperText.innerText = counter;

          if (counter === 8) {
              counterWrapper.classList.add('show');
          }
          
          if (counter === 0) {
              clearInterval(counterInterval);

              setTimeout(() => {
                  interstitialWrapper.classList.remove('show');
                  handleBeforeEach(to, from, next);
              }, 500);
          }

          counter--;
      }, 1000);

      if (progressbar) {
          let width = 1;
          let progressInterval = setInterval(grow, 46);

          function grow() {
              if (width >= 100) {
                  clearInterval(progressInterval);
                  const i = 0;
              } else {
                  width = width + 0.5;
                  progressbar.style.width = width + "%";
              }
          }
      }
    }

    interstitialBtn.addEventListener('click', e => {
        interstitialWrapper.classList.remove('show');
        handleBeforeEach(to, from, next);
    });
  } else {
    handleBeforeEach(to, from, next);
  } 
})

//przenoszenie użytkownika na sg zamiast powrotu na zewnętrzną stronę
router.afterEach((to, from) => {
  //integracja potrzebna do WEBPUSHY
  setTimeout(() => {
    if (window.SR && window.SR.event) {
      window.SR.event.pageVisit()
        .then(function () {
          window.SR.dynamicContent.get();
      })
    }
  }, 500);

  if (!sessionStorage.getItem("back")) {
    if (window.history?.state && window.history.state?.back === null && to.path !== '/') {
      console.log('action1')
      history.pushState(null, null, window.history.state.current);
      sessionStorage.setItem("ps", true);
      window.addEventListener("popstate", popstateHandle);
    } else {
      window.removeEventListener("popstate", popstateHandle);
  
      if (sessionStorage.getItem("ps") && from.path === '/' && to.path === '/') {
        console.log('action4')
        history.pushState(null, null, window.history.state.current);
        window.addEventListener("popstate", popstateSgHandle);
      } else {
        window.removeEventListener("popstate", popstateSgHandle);
      }
    }
  } else {
    window.removeEventListener("popstate", popstateSgHandle);
  }
})

function popstateHandle() {
  console.log('action2')
  window.location.href = location.origin;
}

function popstateSgHandle() {
  console.log('action3')
  sessionStorage.setItem("back", true);
  history.go(-(history.length - 1));
}

function handleBeforeEach(to, from, next) {
  const sectionsStore = useSectionsStore()
  const { pageSettingType, historyPageSettings } = storeToRefs(sectionsStore)
  const { initPage, addHistoryPage } = sectionsStore
  if (!pageSettingType.value) {
    initPage(window.__pageSettings)
    if (window.__pageSettings.type === '404') {
      next('/404')
    }
  }
  const historyPath = historyPageSettings.value[to.path] ?? undefined
  const sessions = JSON.parse(sessionStorage.getItem('historyPage')) || {}
  const sessionsPath = sessions[to.path] ?? undefined

  if (from.path === '/' || (window.event?.type == 'popstate' ?? false)) {
    if (historyPath) {
      initPage(historyPath)
    } else {
      if (sessionsPath) {
        initPage(sessionsPath)
      }
    }
  }
  addHistoryPage(to.path)
  next()
}

function getCookie(name) {
  if (document.cookie !== "") {
      const cookies = document.cookie.split(/; */);

      for (let cookie of cookies) {
          const [ cookieName, cookieVal ] = cookie.split("=");
          if (cookieName === decodeURIComponent(name)) {
              return decodeURIComponent(cookieVal);
          }
      }
  }

  return undefined;
}

export default router